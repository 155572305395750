import React from 'react'
import './Footer.css'
import './responsive/FooterResponsive.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons'
import logo from '../../assets/images/CigaretteLogo.png'
const Footer = () => {
  return (
    <footer className='footer-section'>
      <div className='container'>
        <div className='footer-wrapper'>
          <nav className='footer-nav'>
            <a href='#contact'>Контакт</a>
            <a href='/faq'>FAQ</a>
            <a href='/policy'>Политика</a>
          </nav>
          <div className='footer-logo'>
            <a href='/'>
              <img src={logo} alt='sigaretymoskva Logo' />
            </a>
          </div>
          <div>
            <a href='tel:+7 (925) 831-40-66'>+7 (925) 831-40-66</a>
            <div className='contact'>
              <a href='http://wa.me/79258314066'>
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <a href='https://t.me/Sigaretyoptommoskva'>
                <FontAwesomeIcon icon={faTelegram} />
              </a>
              <a href='mailto:support@sigaretymoskva.com'>
                support@sigaretymoskva.com
              </a>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <p>SigaretyMoskva &copy; 2024. All rights reserved.</p>
          <p>
            *Information provided is for reference and is not a public offer.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
