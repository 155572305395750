import React, { useState, useRef } from 'react'
import './Accordion.css'
import './responsive/FaqResponsive.css'

const Accordion = ({ question, answer }) => {
  const [isActive, setIsActive] = useState(false)
  const answerRef = useRef(null)

  // Toggle accordion open and close
  const toggleAccordion = () => {
    setIsActive(!isActive)
  }

  return (
    <div className={`accordion ${isActive ? 'active' : ''}`}>
      <div className='question' onClick={toggleAccordion}>
        <h3>{question}</h3>
      </div>
      <div
        className='answer'
        ref={answerRef}
        style={{
          height: isActive ? `${answerRef.current.scrollHeight}px` : '0px',
          overflow: 'hidden',
          transition: 'height 0.3s ease',
        }}
      >
        <p>{answer}</p>
      </div>
    </div>
  )
}

export default Accordion
