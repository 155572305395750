import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './mobile-menu/MobileMenu.css'
import './Header.css'
import './Search.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope,faSearch } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons'
import './responsive/HeaderResponsive.css'
import './mobile-menu/MobileMenu.css'
import logo from '../../assets/images/CigaretteLogo.png'

const Header = ({ position, color, visible }) => {
  const [menuOpen, setMenuOpen] = useState(false)
const [headerScrolled, setHeaderScrolled] = useState(false)
  // Function to handle menu opening
  const handleMenuOpen = () => {
    setMenuOpen(true)
  }


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHeaderScrolled(true)
      } else {
        setHeaderScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Function to handle menu closing
  const handleMenuClose = () => {
    setMenuOpen(false)
  }


  const [searchInput, setSearchInput] = useState('')
  const navigate = useNavigate()

  const handleSearch = (e) => {
    e.preventDefault() // Prevent default form submission
    if (searchInput.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchInput.trim())}`)
    }
  }
  return (
    <>
      <header
        className={`header-section ${
          headerScrolled ? 'header-scroll' : ''
        } ${position} ${color}`}
      >
        <div className='header-container container'>
          <div className='header-left'>
            <div className='header-logo'>
              <img src={logo} alt='' />
            </div>
            <div className='header-burger' onClick={handleMenuOpen}>
              <svg class='vbp-header-menu-button__svg'>
                <line
                  x1='0'
                  y1='50%'
                  x2='100%'
                  y2='50%'
                  class='top'
                  shape-rendering='crispEdges'
                />
                <line
                  x1='0'
                  y1='50%'
                  x2='100%'
                  y2='50%'
                  class='middle'
                  shape-rendering='crispEdges'
                />
                <line
                  x1='0'
                  y1='50%'
                  x2='100%'
                  y2='50%'
                  class='bottom'
                  shape-rendering='crispEdges'
                />
              </svg>
            </div>
          </div>
          <div className='header-center'>
            <nav>
              <a href='/'>Главный</a>
              <a className={visible} href='#products'>
                Сигареты
              </a>
              <a className={visible} href='#about'>
                О нас
              </a>
              <a href='#contact'>Контакт</a>
              <a href='/faq'>FAQ</a>
              <a href='/policy'>Политика</a>
            </nav>
            <div className='header-logo-mobile'>
              <a href='/'>
                <img src={logo} alt='' />
              </a>
            </div>
          </div>
          <div className='header-right'>
            <div className='search-container'>
              <form className='search-form' onSubmit={handleSearch}>
                <input
                  id='search-box'
                  class='search-box'
                  type='text'
                  placeholder='Search...'
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <label className='search-label' for='search-box'>
                  <FontAwesomeIcon className='search-icon' icon={faSearch} />
                </label>
                <input type='submit' id='search-submit' />
              </form>
            </div>
          </div>
        </div>
      </header>
      <div
        className={`mobile-menu ${menuOpen ? 'mobile-open' : ''}`}
        onClick={handleMenuClose}
      >
        <div className={`mobile-menu-wrapper`}>
          <div className='header-close' onClick={handleMenuClose}></div>
          <nav className='mobile-menu-nav'>
            <ul>
              <li>
                <a href='/' onClick={handleMenuClose}>
                  Главный
                </a>
              </li>
              <li className={visible}>
                <a href='#products' onClick={handleMenuClose}>
                  Сигареты
                </a>
              </li>
              <li>
                <a href='#contact' onClick={handleMenuClose}>
                  Контакт
                </a>
              </li>
              <li className={visible}>
                <a href='#about' onClick={handleMenuClose}>
                  О нас
                </a>
              </li>
              <li>
                <a href='/faq' onClick={handleMenuClose}>
                  FAQ
                </a>
              </li>
              <li>
                <a href='/policy' onClick={handleMenuClose}>
                  Политика
                </a>
              </li>
            </ul>
          </nav>
          <div className='mobile-contact'>
            <a href='tel:+7 (925) 831-40-66'>+7 (925) 831-40-66</a>
            <div className='contact'>
              <a href='http://wa.me/79258314066'>
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <a href='https://t.me/Sigaretyoptommoskva'>
                <FontAwesomeIcon icon={faTelegram} />
              </a>
              <a href='mailto:info@mostabaktorg.ru'>info@mostabaktorg.ru</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
