import React from 'react'
import './Policy.css'
import './responsive/PolicyResponsive.css'
import Title from '../Title/Title'

const Policy = () => {
  return (
    <div className='policy-section'>
      <Title title='Политика' color='black' />
      <div className='policy-wrapper container'>
        <div>
          <h4>Qaytarma</h4>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nam
            ducimus explicabo est, beatae et magnam architecto voluptatem, non
            assumenda placeat consectetur, cupiditate rem quibusdam commodi
            tenetur vel! Laudantium beatae sed sit dolores, dolor, quae nobis
            quam similique corporis natus debitis harum, obcaecati voluptatibus
            accusantium libero explicabo illum? Quos totam, adipisci eveniet
            soluta modi quis dolor sapiente expedita mollitia debitis laborum
            doloribus ea illum quisquam? Error quaerat nemo deserunt expedita
            similique nostrum. Sapiente tenetur architecto distinctio nam libero
            iusto ipsa aperiam ut doloribus blanditiis consequatur unde dolorem
            impedit cupiditate, recusandae eos numquam est. Blanditiis expedita
            eveniet nobis ex minus magni laborum ipsum esse maiores magnam?
            Mollitia voluptatem earum provident impedit, ea autem, laborum
            consectetur modi facere, illum libero. Sit quos illum id quae
            placeat sed nostrum quod rem quia dignissimos recusandae culpa,
            dolor eos quas cumque, blanditiis officia eligendi aliquid. Officia
            eos ducimus laudantium libero optio recusandae quasi, cumque placeat
            soluta nihil enim aspernatur neque fugiat dolorem obcaecati dicta
            rem quaerat repellat maxime sapiente quibusdam commodi ullam ipsum.
            Quasi, deserunt, eligendi veritatis aperiam corrupti consectetur
            laudantium tempora, atque maiores asperiores soluta. Beatae, veniam
            nihil. Error dolorem nostrum dignissimos repellendus et, id atque
            quia mollitia quis quo beatae alias ad temporibus expedita!
          </p>
        </div>
        <div>
          <h4>Osdur</h4>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nam
            ducimus explicabo est, beatae et magnam architecto voluptatem, non
            assumenda placeat consectetur, cupiditate rem quibusdam commodi
            tenetur vel! Laudantium beatae sed sit dolores, dolor, quae nobis
            quam similique corporis natus debitis harum, obcaecati voluptatibus
            accusantium libero explicabo illum? Quos totam, adipisci eveniet
            soluta modi quis dolor sapiente expedita mollitia debitis laborum
            doloribus ea illum quisquam? Error quaerat nemo deserunt expedita
            similique nostrum. Sapiente tenetur architecto distinctio nam libero
            iusto ipsa aperiam ut doloribus blanditiis consequatur unde dolorem
            impedit cupiditate, recusandae eos numquam est. Blanditiis expedita
            eveniet nobis ex minus magni laborum ipsum esse maiores magnam?
            Mollitia voluptatem earum provident impedit, ea autem, laborum
            consectetur modi facere, illum libero. Sit quos illum id quae
            placeat sed nostrum quod rem quia dignissimos recusandae culpa,
            dolor eos quas cumque, blanditiis officia eligendi aliquid. Officia
            eos ducimus laudantium libero optio recusandae quasi, cumque placeat
            soluta nihil enim aspernatur neque fugiat dolorem obcaecati dicta
            rem quaerat repellat maxime sapiente quibusdam commodi ullam ipsum.
            Quasi, deserunt, eligendi veritatis aperiam corrupti consectetur
            laudantium tempora, atque maiores asperiores soluta. Beatae, veniam
            nihil. Error dolorem nostrum dignissimos repellendus et, id atque
            quia mollitia quis quo beatae alias ad temporibus expedita!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Policy
