import React from 'react'
import './Title.css'
import './responsive/TitleResponsive.css'

const Title = ({ title,color }) => {
  return (
    <div className='title-wrapper'>
      <hr />
      <h2 className={color}>{title}</h2>
      <hr />
    </div>
  )
}

export default Title
