import React from 'react'
import './Banner-Line.css'
import './responsive/Banner-LineResponsive.css'
import Slider from 'react-slick'

const BannerLine = ({color}) => {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    cssEase: 'ease',
    slidesToShow: 6,
    pauseOnHover: false, 
    pauseOnFocus: false,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  }

  return (
    <div className='banner-line'>
      <Slider {...settings} className='carousel'>
        <div className='banner-line-item'>
          <div className='line-round'>18+</div>
          <h3 className={color}>Курение вредит вашему здоровью</h3>
        </div>
        <div className='banner-line-item'>
          <div className='line-round'>18+</div>
          <h3 className={color}>Курение вредит вашему здоровью</h3>
        </div>
        <div className='banner-line-item'>
          <div className='line-round'>18+</div>
          <h3 className={color}>Курение вредит вашему здоровью</h3>
        </div>
        <div className='banner-line-item'>
          <div className='line-round'>18+</div>
          <h3 className={color}>Курение вредит вашему здоровью</h3>
        </div>
        <div className='banner-line-item'>
          <div className='line-round'>18+</div>
          <h3 className={color}>Курение вредит вашему здоровью</h3>
        </div>
        <div className='banner-line-item'>
          <div className='line-round'>18+</div>
          <h3 className={color}>Курение вредит вашему здоровью</h3>
        </div>
      </Slider>
    </div>
  )
}

export default BannerLine
