import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import Home from './pages/Home'
import FAQ from './pages/FAQ'
import Search from './pages/Search'
import Policy from './pages/Policy'
import './App.css'

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className='App'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/policy' element={<Policy />} />
            <Route path='/search' element={<Search />} />
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  )
}

export default App
