import React, { useState, useEffect } from 'react'
import './Faq.css'
import './responsive/FaqResponsive.css'
import Title from '../Title/Title'
import Accordion from './Accordion'
import '../Common/Pagination.css'

const Faq = () => {
  const [faqs, setFaqs] = useState([]) // State to store FAQ data
  const [loading, setLoading] = useState(false) // Loading state for the API call
  const [error, setError] = useState(null) // Error state for any API errors
  const [currentPage, setCurrentPage] = useState(1) // Current page number
  const [pageSize] = useState(4) // Page size for pagination (4 items per page)
  const [totalCount, setTotalCount] = useState(0) // Total FAQ count in the database

  // Function to fetch FAQ data from API
  const fetchFaqs = async (pageNumber, pageSize) => {
    setLoading(true) // Set loading state to true while fetching data
    try {
      const response = await fetch(

        `https://sigaretymoskva.ru/api/faqs?pageNumber=${pageNumber}&pageSize=${pageSize}`
      )
      const result = await response.json()
      if (result.data && result.data.length > 0) {
        setFaqs(result.data) // Update state with fetched FAQ data
        setTotalCount(result.totalCount) // Set total FAQ count
      } else {
        setFaqs([]) // If no data, set FAQ state to empty array
      }
      setLoading(false) // Set loading state to false after fetching
    } catch (err) {
      console.error('Error fetching FAQ data:', err)
      setError('Failed to load FAQs')
      setLoading(false) // Set loading state to false on error
    }
  }

  // Fetch FAQ data when the component mounts or page changes
  useEffect(() => {
    fetchFaqs(currentPage, pageSize)
  }, [currentPage, pageSize])

  // Calculate total pages for pagination
  const totalPages = Math.ceil(totalCount / pageSize)

  // Handle page change (previous or next page)
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber)
    }
  }

  return (
    <div className='faq-section'>
      <Title title='FAQ' color='black' />
      <div className='faq-wrapper container'>
        <div className='faq-accordions'>
          {loading ? (
            <p>Loading...</p> // Show loading state while fetching
          ) : error ? (
            <p>{error}</p> // Show error message if there was an issue
          ) : (
            faqs.map((faq) => (
              <Accordion
                key={faq.id}
                question={faq.question}
                answer={faq.answer}
              />
            ))
          )}
        </div>
      </div>

      {/* Pagination controls */}
      <div className='pagination-container'>
        <button
          className='pagination-button'
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1} // Disable if on the first page
        >
          Назад
        </button>

        <span className='pagination-info'>
          Страница {currentPage} of {totalPages}
        </span>

        <button
          className='pagination-button'
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages} // Disable if on the last page
        >
          Следующий
        </button>
      </div>
    </div>
  )
}

export default Faq
