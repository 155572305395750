// import React, { useState, useEffect } from 'react'
// import './ProductList.css'
// import Product from './Product' // Import the Product component
// import Title from '../Title/Title'

// const ProductList = () => {
//   const [products, setProducts] = useState([]) // Stores the fetched products
//   const [loading, setLoading] = useState(false) // Loading state for the API call
//   const [error, setError] = useState(null) // Error state for any API errors
//   const [currentPage, setCurrentPage] = useState(1) // Keeps track of the current page
//   const [pageSize, setPageSize] = useState(8) // Page size for pagination
//   const [displayCount, setDisplayCount] = useState(8) // Number of products to display
//   const [totalCount, setTotalCount] = useState(0) // Total number of products in the database

//   // Fetch products from API with pagination
//   const fetchProducts = async (pageNumber, pageSize) => {
//     setLoading(true) // Set loading state to true while fetching data
//     try {
//       const response = await fetch(
//         `https://sigaretymoskva.ru/api/product?pageNumber=${pageNumber}&pageSize=${pageSize}`
//       )
//       const result = await response.json()
//       if (result.data && result.data.length > 0) {
//         setProducts(result.data) // Update state with the fetched products
//         setTotalCount(result.totalCount) // Update total product count
//       } else {
//         setProducts([]) // If no data, set products to empty array
//       }
//       setLoading(false) // Set loading state to false after fetching data
//     } catch (err) {
//       console.error('Error fetching products:', err)
//       setError('Failed to load products')
//       setLoading(false) // Set loading state to false on error
//     }
//   }

//   // UseEffect hook to fetch products when the component mounts or the page size changes
//   useEffect(() => {
//     fetchProducts(currentPage, pageSize)
//   }, [currentPage, pageSize])

//   // Handle "Load More" button click to load more products
//   const loadMore = () => {
//     const nextPage = currentPage + 1
//     setCurrentPage(nextPage) // Increment current page
//     setDisplayCount(displayCount + 8) // Increase the number of items to display
//     fetchProducts(nextPage, pageSize) // Fetch products for the next page
//   }

//   // Calculate if there are more products to load
//   const hasMoreProducts = totalCount > displayCount

//   return (
//     <div id='products' className='products-section'>
//       <Title title='Сигареты' />
//       <div className='products-wrapper container'>
//         {/* Loop through the products and display each one using the Product component */}
//         {loading ? (
//           <p>Loading...</p>
//         ) : error ? (
//           <p>{error}</p>
//         ) : (
//           products.map((product) => (
//             <Product key={product.id} product={product} />
//           ))
//         )}
//       </div>
//       <div className='products-bottom container'>
//         <hr />
//         {/* Show "Load More" button only if there are more products to load */}
//         {hasMoreProducts && (
//           <button className='products-load' onClick={loadMore}>
//             Load More
//           </button>
//         )}
//         <hr />
//       </div>
//     </div>
//   )
// }

// export default ProductList

import React, { useState, useEffect } from 'react'
import './ProductList.css'
import Product from './Product' // Import the Product component
import Title from '../Title/Title'

const ProductList = () => {
  const [products, setProducts] = useState([]) // Stores the fetched products
  const [loading, setLoading] = useState(false) // Loading state for the API call
  const [error, setError] = useState(null) // Error state for any API errors
  const [currentPage, setCurrentPage] = useState(1) // Keeps track of the current page
  const [totalPages, setTotalPages] = useState(1) // Total number of pages
  const pageSize = 8 // Page size for pagination

  // Fetch products from API with pagination
  const fetchProducts = async (pageNumber) => {
    setLoading(true) // Set loading state to true while fetching data
    try {
      const response = await fetch(
        `https://sigaretymoskva.ru/api/product?pageNumber=${pageNumber}&pageSize=${pageSize}`
      )
      const result = await response.json()
      if (result.data && result.data.length > 0) {
        setProducts(result.data) // Update state with the fetched products
        setTotalPages(Math.ceil(result.totalCount / pageSize)) // Calculate total pages
      } else {
        setProducts([]) // If no data, set products to empty array
      }
      setLoading(false) // Set loading state to false after fetching data
    } catch (err) {
      console.error('Error fetching products:', err)
      setError('Failed to load products')
      setLoading(false) // Set loading state to false on error
    }
  }

  // UseEffect hook to fetch products when the component mounts or the page changes
  useEffect(() => {
    fetchProducts(currentPage)
  }, [currentPage])

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }

  return (
    <div id='products' className='products-section'>
      <Title title='Сигареты' />
      <div className='products-wrapper container'>
        {/* Loop through the products and display each one using the Product component */}
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          products.map((product) => (
            <Product key={product.id} product={product} />
          ))
        )}
      </div>
      <div className='products-bottom container'>
        <hr />
        {/* Pagination controls */}
        <div className='pagination-container'>
          <button
            className='pagination-button'
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1} // Disable if on the first page
          >
            Назад
          </button>

          <span className='pagination-info'>
            Страница {currentPage} of {totalPages}
          </span>

          <button
            className='pagination-button'
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages} // Disable if on the last page
          >
            Следющий
          </button>
        </div>
        <hr />
      </div>
    </div>
  )
}

export default ProductList
