import React from 'react'
import './Banner.css'
import './responsive/BannerResponsive.css'

const Banner = () => {
  return (
    <div className='banner-section'>
      <div className='banner-container container'>
        <h1>Качество, которое уважает ваш вкус</h1>
        <p>
          Наш сайт не предоставляет возможности для онлайн-покупок, и он не
          является интернет-магазином. Вся предоставленная информация на сайте
          не является рекламой, а служит исключительно для описания разнообразия
          продукции, которую можно приобрести только в наших физических
          розничных магазинах.
        </p>
        <div className='banner-btns'>
          <a href='#about' className='btn-about'>
            О нас
          </a>
          <a href='https://t.me/Sigaretyoptommoskva' className='btn-price'>
            Сделать заказ
          </a>
        </div>
      </div>
      <div className='banner-background'></div>
    </div>
  )
}

export default Banner
