import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import './About.css'
import './responsive/AboutResponsive.css'
import Title from '../Title/Title'

const About = () => {
  const [reviews, setReviews] = useState([]) // State for reviews

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          variableWidth: true,
        },
      },
    ],
  }

  // Fetch reviews from the API
  const fetchReviews = async () => {
    try {
      const response = await fetch('https://sigaretymoskva.ru/api/review') // Update the endpoint if necessary
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const result = await response.json() // Parse JSON data
      setReviews(result) // Update the reviews state
    } catch (error) {
      console.error('Error fetching reviews:', error)
    }
  }

  // Function to modify the review path
  const modifyReviewImagePath = (path) => {
    if (path && path.startsWith('/home/root/Cigarette/media/')) {
      return path.replace('/home/root/Cigarette/media/', '/media/')
    }
    return path
  }

  // Fetch reviews when the component mounts
  useEffect(() => {
    fetchReviews()
  }, [])

  return (
    <div id='about' className='about-section'>
      <Title title='О нас' color='black' />
      <div className='about-wrapper container'>
        <div className='about-left'>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            scelerisque elementum odio, ut dapibus magna cursus sit amet.
            <br />
            <br />
            Duis tincidunt nisl at nibh tempus, a gravida nulla tempor. Donec
            euismod velit a felis suscipit, non consequat est luctus. In hac
            habitasse platea dictumst. Donec a libero vitae libero accumsan
            sodales ut in elit.
          </p>
          <a href='https://t.me/Sigaretyoptommoskva' className='about-btn'>
            Сделать заказ
          </a>
        </div>
        <div className='about-right'>
          <h3>Посылки и заказы</h3>
          <Slider {...settings} className='about-carousel'>
            {reviews.length > 0 ? (
              reviews.map((review) => (
                <div key={review.id}>
                  {/* Ensure images load properly */}
                  <img
                    src={modifyReviewImagePath(review.path)} // Apply path transformation
                    alt={review.altText || 'Review'}
                  />
                </div>
              ))
            ) : (
              <p>Loading reviews...</p> // Show fallback while loading
            )}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default About
