import React from 'react'
import './Layout.css'
import InfoBar from '../InfoBar/InfoBar'
import Header from '../Header/Header'
const Layout = ({color,visible}) => (
  <>
    <div className='layout'>
      <InfoBar />
      <Header color={color} visible={visible} />
    </div>
  </>
)


export default Layout
