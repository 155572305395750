import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Product from '../Products/Product'
import Title from '../Title/Title'
import './SearchResult.css'

const SearchResult = () => {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const searchQuery = queryParams.get('query')

  const fetchProducts = async (pageNumber) => {
    if (!searchQuery) return

    setLoading(true)
    setError(null)

    try {
      const response = await fetch(
        `https://sigaretymoskva.ru/api/search?SearchContent=${searchQuery}&PageNumber=${pageNumber}&PageSize=${pageSize}`
      )

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`)
      }

      const result = await response.json()
      console.log(result)
      const productData = result.data?.flatMap((item) => item.products) || []
      setProducts(productData)
      setTotalPages(result.data?.[0]?.totalPages || 1)
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProducts(currentPage)
  }, [searchQuery, currentPage])

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }

  return (
    <div className='search-result-wrapper container'>
      <Title title={`Поиск Сигарет по запросу ${searchQuery}`} color='black' />

      {loading && <p>Loading...</p>}
      {error && <p className='error-message'>Error: {error}</p>}
      {!loading && !error && products.length === 0 && <p>No products found.</p>}

      {!loading && !error && products.length > 0 && (
        <div className='products-wrapper container'>
          {products.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </div>
      )}

      {/* Pagination controls */}
      <div className='pagination-container'>
        <button
          className='pagination-button'
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1} // Disable if on the first page
        >
          Назад
        </button>

        <span className='pagination-info'>
          Страница {currentPage} of {totalPages}
        </span>

        <button
          className='pagination-button'
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages} // Disable if on the last page
        >
          Следющий
        </button>
      </div>
    </div>
  )
}

export default SearchResult
