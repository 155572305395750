import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import Title from '../Title/Title'
import './BrandsCarousel.css'
import './responsive/BrandResponsive.css'

const BrandsCarousel = () => {
  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(
          'https://sigaretymoskva.ru/api/brand?PageNumber=1&PageSize=20'
        )
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`)
        }
        const responseData = await response.json()


        // Use lowercase 'data' as per API response
        setBrands(responseData.data || []) // Default to empty array if undefined
        setLoading(false)
      } catch (err) {
        setError(err.message)
        setLoading(false)
      }
    }

    fetchBrands()
  }, [])

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    arrows: false,
    pauseOnHover: false,
    cssEase: 'ease',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  if (loading) {
    return <div className='brands-section'>Loading...</div>
  }

  if (error) {
    return <div className='brands-section'>Error: {error}</div>
  }

  return (
    <div className='brands-section'>
      <Title title='Бренды' color='white' />
      <div className='brands-line'>
        <Slider {...settings} className='brands-carousel'>
          {brands.length > 0 ? (
            brands.map((brand) => (
              <div key={brand.id} className='brand-item'>
                <p>{brand.name}</p>
              </div>
            ))
          ) : (
            <div>No brands available</div>
          )}
        </Slider>
      </div>
    </div>
  )
}

export default BrandsCarousel
