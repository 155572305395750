import React from 'react'
import './InfoBar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons'
import './responsive/InfoBarResponsive.css'

const InfoBar = () => {
  return (
    <div className='info-bar'>
      <div className='container'>
        <div className='info-wrapper'>
          <a href='tel:+7 (925) 831-40-66'>+7 (925) 831-40-66</a>
          <a href='mailto:support@sigaretymoskva.com'>
            support@sigaretymoskva.com
          </a>
          <div>
            <a href='http://wa.me/79258314066'>
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a href='https://t.me/Sigaretyoptommoskva'>
              <FontAwesomeIcon icon={faTelegram} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBar
