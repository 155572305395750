import React, { useState } from 'react'
import './ContactForm.css'
import './responsive/ContactResponsive.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Title from '../Title/Title'
import Swal from 'sweetalert2'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch('https://sigaretymoskva.ru/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (response.status === 200) {
        Swal.fire(
          'Success!',
          'Your message has been sent successfully!',
          'success'
        )
      } else {
        Swal.fire('Error', 'There was an issue sending your message.', 'error')
      }
    } catch (error) {
      Swal.fire('Error', 'There was an issue sending your message.', 'error')
    }
  }

  return (
    <div id='contact' className='contact-section'>
      <div className='contact-wrapper container'>
        <Title title='Связаться с нами' color='black' />
        <p>
          Если у вас есть какие-либо вопросы, свяжитесь с нами. Наша служба
          поддержки клиентов работает круглосуточно и без выходных. После
          заполнения формы с вами свяжутся.
        </p>
        <form onSubmit={handleSubmit}>
          <div className='contact-row'>
            <input
              type='text'
              name='name'
              value={formData.name}
              onChange={handleInputChange}
              placeholder='Имя'
              required
            />
            <input
              type='email'
              name='email'
              value={formData.email}
              onChange={handleInputChange}
              placeholder='Электронная почта'
              required
            />
          </div>
          <input
            type='text'
            name='phoneNumber'
            value={formData.phoneNumber}
            onChange={handleInputChange}
            placeholder='Телефон'
            required
          />
          <textarea
            name='message'
            value={formData.message}
            onChange={handleInputChange}
            placeholder='Сообщение'
            required
          ></textarea>
          <button type='submit' className='submit-btn'>
            Отправлять <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
