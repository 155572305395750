import React from 'react'
import SearchResult from '../components/Search/SearchResult'
import ContactForm from '../components/Contact/ContactForm'
import Footer from '../components/Footer/Footer'
import Line from '../components/Banner/Banner-Line'
import Social from '../components/SocialContact/Socials'
import { Helmet } from 'react-helmet-async'
import Layout from '../components/Layout/Layout'
const Search = () => {
  return (
    <>
      <Helmet>
        <title>Результаты поиска - SigaretyMoskva</title>
        <meta
          name='description'
          content='Результаты поиска по вашему запросу. Найдите оригинальные сигареты по лучшей цене в Москве.'
        />
        <meta
          name='keywords'
          content='поиск сигарет Москва, сигареты дешево оптом, найти сигареты'
        />
      </Helmet>
      <div className='search-result'>
        <Layout color='header-dark-blue' visible='display-none' />

        <SearchResult />
        <Line color='black' />
        <ContactForm />
        <Footer />
        <Social />
      </div>
    </>
  )
}

export default Search
