import React from 'react'
import './Socials.css'
import Telegram from './Contact-Telegram'
import WhatsApp from './Contact-WhatsApp'

const Social = () => {
  return (
    <div className='social'>
      <Telegram />
      <WhatsApp />
    </div>
  )
}

export default Social
