import React from 'react'
import Banner from '../components/Banner/Banner'
import BannerLine from '../components/Banner/Banner-Line'
import ProductList from '../components/Products/ProductList'
import About from '../components/About/About'
import BrandsCarousel from '../components/Brands/BrandsCarousel'
import ContactForm from '../components/Contact/ContactForm'
import Footer from '../components/Footer/Footer'
import Social from '../components/SocialContact/Socials'
import { Helmet } from 'react-helmet-async'
import Layout from '../components/Layout/Layout'

const Home = () => {
  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>Купить сигареты оптом в Москве - Дешевые и Оригинальные</title>
        <meta
          name='description'
          content='Продажа сигарет оптом и в розницу в Москве. Дешевые и оригинальные сигареты высокого качества по лучшим ценам. Быстрая доставка по всей Москве!'
        />
        <meta
          name='keywords'
          content='сигареты оптом, сигареты дешево, оригинальные сигареты, купить сигареты в Москве, продажа сигарет, wholesale cigarettes Moscow, cheap cigarettes Moscow'
        />
        <meta name='author' content='SigaretyMoskva' />
        <meta name='robots' content='index, follow' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />

        {/* Open Graph / Social Media Tags */}
        <meta
          property='og:title'
          content='Купить сигареты оптом в Москве - Дешевые и Оригинальные'
        />
        <meta
          property='og:description'
          content='Ищете дешевые и оригинальные сигареты оптом в Москве? Мы предлагаем сигареты высокого качества по доступным ценам. Заходите и заказывайте!'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://sigaretymoskva.ru' />
        <meta
          property='og:image'
          content='https://sigaretymoskva.ru/your-og-image.jpg'
        />
        <meta property='og:locale' content='ru_RU' />

        {/* Twitter Card Tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:title'
          content='Купить сигареты оптом в Москве - Дешевые и Оригинальные'
        />
        <meta
          name='twitter:description'
          content='Дешевые и оригинальные сигареты оптом в Москве. Доставка по всей Москве. Заказывайте качественные сигареты прямо сейчас!'
        />
        <meta
          name='twitter:image'
          content='https://sigaretymoskva.ru/twitter-image.jpg'
        />

        <link rel='canonical' href='https://sigaretymoskva.ru' />

        {/* Favicon */}
        <link rel='icon' href='https://sigaretymoskva.ru/favicon.ico' />
      </Helmet>
      <div className='home'>
        <Layout/>
        <Banner />
        <BannerLine />
        <ProductList />
        <About />
        <BrandsCarousel />
        <ContactForm />
        <Footer />
        <Social />
      </div>
    </>
  )
}

export default Home
