import React from 'react'
import PolicySection from '../components/Policy/Policy'
import ContactForm from '../components/Contact/ContactForm'
import Footer from '../components/Footer/Footer'
import Line from '../components/Banner/Banner-Line'
import Social from '../components/SocialContact/Socials'
import { Helmet } from 'react-helmet-async'
import Layout from '../components/Layout/Layout'

const Policy = () => {
  return (
    <>
      <Helmet>
        <title>Политика конфиденциальности - SigaretyMoskva</title>
        <meta
          name='description'
          content='Политика конфиденциальности МосТабакТорг: узнайте, как мы защищаем ваши данные.'
        />
        <meta
          name='keywords'
          content='политика конфиденциальности, защита данных'
        />
      </Helmet>
      <div className='policy'>
        <Layout color='header-dark-blue' visible='display-none'/>
        <PolicySection />
        <Line color='black' />

        <ContactForm />
        <Footer />
        <Social />
      </div>
    </>
  )
}

export default Policy
