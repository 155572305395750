import React from 'react'
import Faq from '../components/Faq/Faq'
import ContactForm from '../components/Contact/ContactForm'
import Footer from '../components/Footer/Footer'
import Line from '../components/Banner/Banner-Line'
import Social from '../components/SocialContact/Socials'
import Layout from '../components/Layout/Layout'
import { Helmet } from 'react-helmet-async'

const FAQ = () => {
  return (
    <>
      <Helmet>
        <title>Часто задаваемые вопросы - SigaretyMoskva</title>
        <meta
          name='description'
          content='Ответы на часто задаваемые вопросы о покупке сигарет оптом и в розницу в Москве.'
        />
        <meta
          name='keywords'
          content='сигареты оптом FAQ, доставка сигарет, помощь при заказе сигарет'
        />
      </Helmet>
      <div className='faq'>
        <Layout color='header-dark-blue' visible='display-none' />

        <Faq />
        <Line color='black' />

        <ContactForm />
        <Footer />
        <Social />
      </div>
    </>
  )
}

export default FAQ
