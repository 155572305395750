import React, { useEffect, useState } from 'react'
import './Product.css'
import './responsive/ProductResponsive.css'

const Product = ({ product }) => {
  const [brandName, setBrandName] = useState('')

  useEffect(() => {
    const fetchBrandName = async () => {
      try {
        const response = await fetch(
          `https://sigaretymoskva.ru/api/brand/getById?id=${product.brandId}`
        )
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`)
        }
        const brandData = await response.json()
        setBrandName(brandData.name)
      } catch (err) {
        console.error('Brand Fetch Error:', err)
      }
    }

    fetchBrandName()
  }, [product.brandId])

  // Function to modify the product path
  const modifyImagePath = (path) => {
    if (path && path.startsWith('/home/root/Cigarette/media/')) {
      return path.replace('/home/root/Cigarette/media/', '/media/')
    }
    return path || 'default-image-path'
  }

  return (
    <div className='product-item'>
      <div className='product-img'>
        <img
          src={modifyImagePath(product.path)}
          alt={product.altText || 'product'}
        />
      </div>
      <div className='product-info'>
        <div className='product-details'>
          <p>
            <span>Бренд:</span> {brandName || 'Загрузка...'}
          </p>
          <p>{product.isStock ? 'В наличии' : 'Распродано'}</p>
        </div>
        <h3>{product.name}</h3>
        <p className='product-price'>
          Цена: <span>{product.price} ₽</span>
        </p>
        <a href='https://t.me/Sigaretyoptommoskva' className='product-btn'>
          заинтересованный
        </a>
      </div>
    </div>
  )
}

export default Product
